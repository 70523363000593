@media only screen and (min-width:961px) {
  .Screen {
  
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; 
  
  
    flex-direction: row;
    /*background-color: rgb(47, 47, 47);*/
  }

  .App-portrait-mode {
    text-align: center;
    height: 600px;
    width: 400px;
  
    /*border-radius: 10px;*/
    /*border: 1px solid #000;*/

    display: flex;
    flex-direction: column;
    background-color: rgb(27, 27, 27);
    margin: auto;
  }

  .App-box-mode {
    text-align: center;
    height: 400px;
    width: 400px;
  
    /*border-radius: 10px;*/
    /*border: 1px solid #000;*/

    display: flex;
    flex-direction: column;
    background-color: rgb(27, 27, 27);
    margin: auto;
  }

  .App-landscape-mode {
    text-align: center;
    width: 600px;
    height: 400px;
  
    /*border-radius: 10px;*/
    /*border: 1px solid #000;*/

    display: flex;
    flex-direction: column;
    background-color: rgb(27, 27, 27);
    margin: auto;
  }


}

@media only screen and (max-width: 961px) {
  .Screen {
  
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; 
  
  
    flex-direction: column;
    background-color: rgb(47, 47, 47);
  }

  .App-portrait-mode,
  .App-box-mode,
  .App-landscape-mode {
    text-align: center;
    background-color: rgb(27, 27, 27);
    height: 100vh;
    width: 100vw;
  
    /* display as rows */
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-title {
  font-size: 1.5em;
  color: white;
  margin:auto
}

.App-div {
  display: flex;
  flex: 1;
}

.Button-div {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.App-div-column {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.App-global-message {
  font-size: 0.5em;
  margin: auto;
  padding: 10px;
  white-space: pre-line;
}

.App-ad-title {
  font-size: 25px;
  margin: auto;
  padding: 30px;
}

.App-ad-text {
  font-size: 14px;
  /*margin: auto;*/
  padding: 20px;
  margin: 20px;
  display: block;
  overflow: auto;
  white-space: pre-line;
  text-align: left;
  height: 100px;
  border-color: white;
  border-style: solid;
  border-width: 1px;
}

.App-button {
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-top: 30px;
}

.App-button-nobottommargin {
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
}

.App-button-hide {
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 5px;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  padding: 10px 15px;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-top: 30px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: white;
  font-size: 0.7em;
  margin-top: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Bottom {
  height: 50px; /* oder eine andere Höhe, die Sie möchten */
  display: flex;
  justify-content: center;
  align-items: center;
  
  padding: 5px;
}

.radio-container {
  display: flex;
  gap: 10px; /* Ein bisschen Abstand hinzugefügt */
  align-items: center;
  justify-content: center;
}

.radio-container label {
  color: white;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.radio-container input[type="radio"] {
  appearance: none;
  width: 12px; /* Größe angepasst */
  height: 12px; /* Größe angepasst */
  position: relative;
  /*margin-right: 5px; /* Abstand angepasst */
  outline: none;
  margin: auto;
  background-color: transparent;
}

.radio-container input[type="radio"]::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  width: 12px; /* Größe angepasst */
  height: 12px; /* Größe angepasst */
  border: 0.75px solid white; /* Linienstärke angepasst */
  border-radius: 50%;
}

.radio-container input[type="radio"]:checked::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  width: 6px; /* Größe angepasst */
  height: 6px; /* Größe angepasst */
  background-color: white;
  border-radius: 50%;

}

.radio-container input[type="radio"]:focus::before {
  box-shadow: 0 0 0 1.5px rgba(255, 255, 255, 0.5);

  background-color: transparent;
}

.profile-image-container {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 10px;
}

h1 {
  font-size: 1em;
}

h2 {
  font-size: 0.8em;
}

p {
  font-size: 0.5em;
}
