.chat-header {
    display: flex;
    align-items: center;
    padding: 10px;
    /*border-bottom: 1px solid #ccc;*/

    background-color: #404040;
  }
  
  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;  /* Dies macht das Bild rund */
    margin-right: 10px;
    object-fit: cover;   /* Damit das Bild korrekt in den runden Rahmen passt */
  }
  
  .chat-name {
    font-size: 20px;
    font-weight: bold;
    color: white;
  }
  
  .chat-time {
    /* right side */
    margin-left: auto;
    font-size: 20px;
    color: white;
    padding-right: 10px;
  }

  .report-button {
    background-color: transparent;
    border: none;
    padding: 0px;
    padding-top: 4px;
  }