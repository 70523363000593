@media only screen and (min-width:961px) {
    .chat-container {
        width: 100%;
        height: 100%;
        /*border: 1px solid #ccc;*/
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: rgb(27, 27, 27);
        margin: auto;
        
      }
  }

  @media only screen and (max-width: 961px) {
    .chat-container {
        width: 100vw;
        /*height: 75vh;*/
        height: 100vh;
        /*border: 1px solid #ccc;*/
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: rgb(27, 27, 27);
    }
  }

  .input-container {
    display: flex;
    background-color: grey;
    /*border-top: 1px solid #ccc;*/
  }

  .input-message {
    outline: none;
    color: white;
  }
  
  input {
    flex: 1;
    padding: 12px;
    border: none;
    background-color: rgb(45, 45, 45);
  }
  
  button {
    padding: 10px 15px;
    border: none;
    background-color: #007BFF;
    color: white;
  }

  .SendButton {
    color:rgb(27, 27, 27);
    border-radius: 0px;
    background-color: #404040;
  }