.message {
    padding: 5px 10px;
    border-radius: 7px;
    margin-bottom: 5px;
    width: fit-content;
  }
  
  .message-me {
    background-color: rgb(54, 211, 94);
    align-self: flex-end;
    border-bottom-right-radius: 0;
    color: white;
    font-size: 0.8em;
    margin: 4px;
    text-align: left;
    max-width: 75%;
  }
  
  .message-other {
    background-color: #404040;
    align-self: flex-start;
    border-bottom-left-radius: 0;
    color: white;
    font-size: 0.8em;
    margin: 4px;
    text-align: left;
    max-width: 75%;
  }
  
  .message-note {
    align-self: center;
    border-bottom-left-radius: 0;
    border-radius: 6px;
    margin-bottom: 6px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 0.8em;
    margin: 4px;
  }

.message-note-text {
    padding: 5px 10px;
    border-radius: 6px;
    width: fit-content;
    white-space: pre-wrap;
    text-align: center;
}

.message-note-button {
    padding: 5px 10px;
    border-radius: 7px;
    width: fit-content;
}

.messages-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1;
    padding: 10px;
  }

  .messages-list-item {
    display: flex;
    flex-direction: column;
  }

  .message-decide {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }


  .message-decide-button {
    width: 100px;
    height: 50px;
    margin: 10px;
    padding: 5px;
    border-radius: 7px;
    color: black;
    background-color: white;
  }

  .result-image-container {
    width: 40px;
    height: 40px;
  }

  .result-container {
    padding: 10px;
    text-align: 'center';
    color: 'white';
    align-items: 'center',
  }

  .result-image-text {
    text-align: center;
  }

  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 10px;
  }

  .icon-image {
    width: 40px;
    height: 40px;
  }